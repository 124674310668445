import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { publishLast, refCount } from "rxjs/operators";
import { Constants } from "../utils/constants";

@Injectable({
    providedIn: 'root'
  })
  export class ApiExternalService  {
  
  
    constructor(protected http: HttpClient) {
    }
  
  
    listarCiudades(): Observable<Object> {
      return this.http.get(Constants.API_ENDPOINT+Constants.URL_LISTA_DEPARTAMENTOS);
    }
  

  }