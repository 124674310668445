import { Component, OnInit, Compiler, Input } from '@angular/core';
import {IdentityService, IdentityUser} from "../../../../core/security";
import * as moment from "moment";
@Component({
  selector: 'app-menu-superior',
  templateUrl: './menu-superior.component.html',
  styleUrls: ['./menu-superior.component.scss']
})
export class MenuSuperiorComponent implements OnInit {
  private userMenuActive: boolean | undefined;

  constructor(protected _runtimeCompiler: Compiler,  private identityService: IdentityService ) {
    this._runtimeCompiler.clearCache();
  }

  @Input() usuario: any;
  // @ts-ignore
  user: IdentityUser ;
  // @ts-ignore
  lista: Object;



  ngOnInit() {
    this.identityService
      .getUserInfo()
      .subscribe((userInfo) => (this.user = userInfo));
  }
  convertirFechas(valor : string) {
    if (valor && valor !== '') {
      const valorFecha: number = parseInt(valor);
      return  moment(new Date(valorFecha)).format('DD-MM-YYYY HH:mm:ss');
    } else {
      return '';
    }

  }
}
