<form>

	<div class="cd-filter-block" (click)="goHome()">
		<h4 class="no-image">
			<i class="fa fa-home" aria-hidden="true"></i> &nbsp;Home
		</h4>
	</div>




  <div  class="cd-filter-block "
       (click)="mostrarComponente('GestionJubilados')">
    <h4 class="no-image" [ngClass]="{active: moduloActual == 'GestionJubilados'}">
      <i class="fa fa-refresh" aria-hidden="true"></i> &nbsp;Gestión</h4>
  </div>

</form>
