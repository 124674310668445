import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from './views/shared/shared.module';
import { APP_ROUTES } from './routes';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpInterceptRequestService} from "../core/http-interceptors";

/**
 * Modulo que incluye los componentes específicos y propios de la aplicación
 */
@NgModule({
  imports: [SharedModule, RouterModule.forRoot(APP_ROUTES)],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptRequestService, multi: true },
// gen_providers

],
  declarations: []
})
export class RoutesModule {}
