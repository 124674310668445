import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../utils/constants';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptRequestService implements HttpInterceptor {
  /**
   * Constructor de la clase
   *
   * @param router objeto para controlar el enrutamiento de la aplicación
   */
  constructor(private router: Router, private  location: Location) {}

  /**
   * intercepta las peticiones
   *
   * @param req petición
   * @param next handler para ejecutar la logica cuando se captura la petición
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var reqWithHeaders;
    if(req.url.includes(Constants.securityUserAccess)) {
      reqWithHeaders = req.clone({
        withCredentials: true,
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-APP-RELAYSTATE': this.location.path()
        })
      });
    } else {
      reqWithHeaders = req.clone({
        withCredentials: true,
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'X-APP-RELAYSTATE': this.location.path()
        })
      });
    }

    return next.handle(reqWithHeaders).pipe(
      // @ts-ignore
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              // Presenta la pagina de login desde seus
              document.write(err.error);
              return throwError(err);
            case 403:
              this.router.navigate(['/access-denied']);
              return throwError(err);
            default:
              return throwError(err);
          }
        }
      })
    );
  }
}
