import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
// @ts-ignore
import { HttpModule } from '@angular/http';
import { DatePipe, NgClass } from '@angular/common';
import { CommonModule, HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { CoreModule } from './core/core.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './routes/views/shared/shared.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './routes/views/shared/header/header.component';
import { MenuComponent } from './routes/views/shared/menu/menu.component';
import { MenuSuperiorComponent } from './routes/views/shared/menu-superior/menu-superior.component';
import { MensajesAlertasService } from './core/api-services/mensajes-alertas.service';
import { LoadingService } from './core/api-services/loading.service';
import { GestionJubiladosComponent } from './routes/views/gestion-jubilados/gestion-jubilados.component';
import {TableModule} from "primeng/table";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpInterceptRequestService} from "./core/http-interceptors";
import { IndexComponent } from './routes/views/shared/index/index.component';
@NgModule({
  declarations: [AppComponent, HeaderComponent, MenuComponent, MenuSuperiorComponent, IndexComponent, GestionJubiladosComponent],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        RoutesModule,
        BrowserModule,
        FormsModule,
        HttpModule,
        CommonModule,
        SharedModule.forRoot(),
        TableModule
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptRequestService, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    MensajesAlertasService,
    LoadingService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
