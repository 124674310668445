export class DatosContactoJubiladoModel {
    // @ts-ignore
    cdempleado: string = null;
    // @ts-ignore
    numeroDocumento: string = null;
    // @ts-ignore
    comentario: string;
    // @ts-ignore
    fechaRegistro: string = null;
    // @ts-ignore
    usuarioRegistro: string = null;
    // @ts-ignore
  }
  