import { Component, OnInit } from '@angular/core';
import 'rxjs/add/observable/forkJoin';
import { MensajesAlertaForm, MensajesAlertasService } from "../../../core/api-services/mensajes-alertas.service";
import { LoadingService } from "../../../core/api-services/loading.service";
import { ApiService } from "../../../core/api-services/api.service";
import { DatosJubiladoModel } from '../../../core/models/DatosJubiladoModel';
import { DatosContactoJubiladoModel } from '../../../core/models/DatosContactoJubiladoModel';
import { IdentityService, IdentityUser } from 'src/app/core/security';
import { ResponseModel } from 'src/app/core/models/ResponseModel';
import { DatePipe } from '@angular/common';
import { ApiExternalService } from 'src/app/core/api-services/api.external.service';
import { ListaBancosModel } from 'src/app/core/models/ListaBancosModel';

declare var abrirModal: any;
declare var cerrarModal: any;
declare var accionCerrarFormulario: any;
declare var accionAbrirFormulario: any;

@Component({
  selector: 'app-gestion-jubilados',
  templateUrl: './gestion-jubilados.component.html',
  styleUrls: ['./gestion-jubilados.component.scss']
})
export class GestionJubiladosComponent implements OnInit {
  public user!: IdentityUser;
  public data: Object[] = [];
  public dataContacto: Object[] = [];
  public lista: any[] = [];
  public listaContactoJubilado: any[] = [];
  public operacion: string = "";
  public fila: any = new Object();
  public datos = new DatosJubiladoModel();
  public datosJubilado = new DatosJubiladoModel();
  public datosContactoJubilado = new DatosContactoJubiladoModel();
  public jubiladoSustituto = new DatosJubiladoModel();
  public jubiladosCols: any[] = [];
  public contactosJubiladoCols: any[] = [];
  public listaBancos: ListaBancosModel[] = [];
  public descJubilado: string = "Jubilado";
  public tipoJubilado: string = "J";
  public response!: ResponseModel;
  public visible = true;
  public tipoValidacionJubilado: string = "J";
  public ciudadades: Array<string> = [];
  public listaCiudades: ListaBancosModel[] = [];
  public mensaje_ok: string = "";
  public mensaje_error: string = "";
  public archivoSeleccionado: File | null = null;
  public tipoArchivo: string = "";
  public textoBoton: string = "Cargar archivo";
  public isDisabled = false;
  public isLoading = false;


  constructor(public identityService: IdentityService,
    public jubiladosService: ApiService,
    public mensajesAlertaService: MensajesAlertasService,
    public loadingService: LoadingService,
    private datePipe: DatePipe,
    public externalService: ApiExternalService) {
  }


  ngOnInit() {
    this.consultarJubilados("{}");
    this.jubiladosCols = [
      { header: '', field: 'actions' },
      { header: 'Código', field: 'cdempleado', filter: true, tipo: 'texto', valores: [], formato: 'texto' },
      { header: 'Tipo Documento', field: 'tipoDocumento', filter: false, formato: 'texto' },
      { header: 'Número Documento', field: 'numeroDocumento', filter: true, tipo: 'texto', valores: [], formato: 'texto' },
      { header: 'Nombre', field: 'primerNombre', filter: true, formato: 'texto', tipo: 'texto', valores: [] },
      { header: 'Apellido', field: 'primerApellido', filter: true, formato: 'texto', tipo: 'texto', valores: [] },
      { header: 'Estado Vinculación', field: 'estadoVinculacion', filter: true, tipo: 'lista', valores: [], formato: 'texto' },
      { header: 'Tipo Jubilación', field: 'tipoJubilacion', filter: false, formato: 'texto' },
      { header: 'Fecha Nacimiento', field: 'fechaNacimiento', filter: false, formato: 'fecha' },
      { header: 'Fecha Ingreso', field: 'fechaIngreso', filter: false, formato: 'fecha' },
      { header: 'Fecha Inicio Pago', field: 'fechaInicioPago', filter: false, formato: 'fecha' }
    ];

    this.contactosJubiladoCols = [
      { header: 'Comentario', field: 'comentario', filter: true, tipo: 'texto', formato: 'texto' },
      { header: 'Usuario Registro', field: 'usuarioRegistro', filter: false, formato: 'texto' },
      { header: 'Fecha Registro', field: 'fechaRegistro', filter: false, formato: 'fecha' }
    ];

    this.listaBancos = [
          {cod: "1", nombre: "BANCO DE BOGOTA"},
          {cod: "2", nombre: "BANCO POPULAR"},
          {cod: "3", nombre: "BANCOLOMBIA"},
          {cod: "4", nombre: "BANCO CAFETERO"},
          {cod: "5", nombre: "BANCO DEL COMERCIO"},
          {cod: "6", nombre: "BANCO CORPBANCA"},
          {cod: "7", nombre: "BANCOLOMBIA(NO APLICA)"},
          {cod: "8", nombre: "BANCO GANADERO"},
          {cod: "9", nombre: "BANCO CITIBANK"},
          {cod: "10", nombre: "BANCO DE CALDAS"},
          {cod: "11", nombre: "CORPOR. DE AHORRO Y VIV. DAVIV"},
          {cod: "13", nombre: "BANCO BILBAO VIZCAYA (BBVA)"},
          {cod: "14", nombre: "ITAU"},
          {cod: "15", nombre: "BANCO ANGLO COLOMBIANO"},
          {cod: "16", nombre: "CORP. DE AHORRO Y VIV. COLPATR"},
          {cod: "17", nombre: "BANCO INTERNACIONAL"},
          {cod: "18", nombre: "BANCO MERCANTIL"},
          {cod: "19", nombre: "BANCO COLPATRIA MULTIBANCA S.A"},
          {cod: "20", nombre: "FONDO DE CESANTIA DAVIVIR"},
          {cod: "21", nombre: "COLFONDOS PENSIONES Y CESANTIA"},
          {cod: "22", nombre: "COLMENA FONDOS DE PENSIONES"},
          {cod: "23", nombre: "BANCO DE OCCIDENTE"},
          {cod: "30", nombre: "BANCO CAJA SOCIAL"},
          {cod: "43", nombre: "BANCO AGRARIO"},
          {cod: "49", nombre: "BANCO AV VILLAS"},
          {cod: "50", nombre: "BANCAFE"},
          {cod: "70", nombre: "BANCO DE BOGOTA (NO APLICA)"},
          {cod: "90", nombre: "BANCO SUDAMERIS"},
          {cod: "110", nombre: "CONAVI (NO APLICA)"},
          {cod: "900", nombre: "FONDO DE CESANTIAS PROTECCION"},
          {cod: "901", nombre: "FONDO DE CESANTIAS PORVENIR"},
          {cod: "902", nombre: "FONDO DE CESANTIAS SKANDIA"},
          {cod: "903", nombre: "FONDO DE CESANTIAS HORIZONTE"},
          {cod: "904", nombre: "FONDO NACIONAL DE AHORRO FNA"},
          {cod: "905", nombre: "FONDO DE CESANTIAS ING"},
          {cod: "906", nombre: "FONDO DE CESANTIAS SANTANDER"},
          {cod: "908", nombre: "FONDO DE CESANTIAS OLD MUTUAL"},
          {cod: "1004", nombre: "BANCO COOMEVA"},
          {cod: "1014", nombre: "HELM BANK S.A."},
          {cod: "1058", nombre: "BANCO PROCREDIT COLOMBIA"},
          {cod: "1060", nombre: "BANCO PICHINCHA"},
          {cod: "1061", nombre: "BANCOOMEVA"},
          {cod: "1062", nombre: "BANCO FALABELLA"},
          {cod: "1063", nombre: "BANCO FINANDINA S.A."},
          {cod: "1066", nombre: "COOPCENTRAL"},
          {cod: "1070", nombre: "LULO BANK"},
          {cod: "1121", nombre: "FINANCIARA JURISCOOP S.A. COMP"},
          {cod: "1292", nombre: "CONFIAR COOPERATIVA FINANCIERA"},
          {cod: "1507", nombre: "NEQUI"},
          {cod: "1551", nombre: "DAVIPLATA"},
          {cod: "9999", nombre: "NO APLICA"}
    ];

    this.listaCiudades = [
      {cod: "05001", nombre: "MEDELLIN"},
      {cod: "08001", nombre: "BARRANQUILLA"},
      {cod: "11001", nombre: "BOGOTA"},
      {cod: "17001", nombre: "MANIZALES"},
      {cod: "19001", nombre: "POPAYAN"},
      {cod: "47001", nombre: "SANTA MARTA"},
      {cod: "54001", nombre: "CUCUTA"},
      {cod: "63001", nombre: "ARMENIA"},
      {cod: "66001", nombre: "PEREIRA PJ"},
      {cod: "68001", nombre: "BUCARAMANGA"},
      {cod: "70001", nombre: "SINCELEJO"},
      {cod: "73001", nombre: "IBAGUE"},
      {cod: "73268", nombre: "ESPINAL"},
      {cod: "76001", nombre: "CALI"},
      {cod: "76111", nombre: "BUGA"},
      {cod: "76520", nombre: "PALMIRA"},
      {cod: "76834", nombre: "TULUA"}
    ];

    this.identityService
      .getUserInfo()
      .subscribe((userInfo) => (this.user = userInfo));

    this.externalService
      .listarCiudades()
      .subscribe((lista: any) => (this.ciudadades = lista));

  }

  limpiarFormulario() {
    this.datosJubilado = new DatosJubiladoModel();
    this.descJubilado = "Jubilado";
    this.tipoValidacionJubilado = "J";
  }

  limpiarFormularioContacto() {
    this.datosContactoJubilado.comentario = '';
  }

  setearRespuesta(respuesta: Object) {
    // @ts-ignore
    this.data = respuesta;
    this.lista = this.data;
    this.loadingService.ocultarLoading();
  }

  setearRespuestaContacto(respuesta: Object) {
    // @ts-ignore
    this.dataContacto = respuesta;
    this.listaContactoJubilado = this.dataContacto;
    this.loadingService.ocultarLoading();
  }

  verDetalle(fila: Object) {
    this.isDisabled = false;
    this.loadingService.mostrarLoading();
    this.operacion = 'detalle';
    this.datosJubilado = fila as DatosJubiladoModel;
    this.loadingService.ocultarLoading();
    this.limpiarCargaArchivos();
    this.limpiarMensajes();
    accionAbrirFormulario();
  }

  verContacto(fila: Object) {
    this.loadingService.mostrarLoading();
    this.operacion = 'contacto';
    this.datosContactoJubilado = fila as DatosContactoJubiladoModel;
    this.datosJubilado = fila as DatosJubiladoModel;
    this.consultarContactosJubilado(this.datosContactoJubilado.numeroDocumento);
    this.loadingService.ocultarLoading();
    accionAbrirFormulario();
  }

  consultarJubilados(filtro: string) {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .consultarJubilados(filtro)
      .subscribe(
        respuesta => this.setearRespuesta(respuesta),
        error => this.mostrarAlerta(<any>error)
      );
  }

  consultarContactosJubilado(filtro: string) {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .consultarContactosJubilado(filtro)
      .subscribe(
        respuesta => this.setearRespuestaContacto(respuesta),
        error => this.mostrarAlerta(<any>error)
      );
  }

  adicionarSustituto() {
    this.descJubilado = "Sustituto";
    this.jubiladoSustituto.cdTipoDocumentoJubiladoPpal = this.datosJubilado.tipoDocumento;
    this.jubiladoSustituto.nmDocumentoJubiladoPpal = this.datosJubilado.numeroDocumento;
    this.jubiladoSustituto.dsNombreJubiladoPpal = this.datosJubilado.primerNombre + " " + this.datosJubilado.primerApellido;
    this.jubiladoSustituto.cargo = this.datosJubilado.cargo;
    this.jubiladoSustituto.fechaIngreso = this.datosJubilado.fechaIngreso;
    this.jubiladoSustituto.tipoJubilacion = "Sustituto";
    this.datosJubilado = this.jubiladoSustituto;
    this.tipoValidacionJubilado = "S";
    this.isDisabled = true;
  }


  guardar() {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .adicionarSustituto(this.datosJubilado, this.user.userName)
      .subscribe(
        respuesta => {
          this.mostrarAlerta(new MensajesAlertaForm("ok", respuesta.message))
        },
        (respuestaError: any) => {
          console.log(respuestaError);
          this.mostrarAlerta(new MensajesAlertaForm("error", respuestaError.error.error))
        }
      );
  }

  darBaja() {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .darBajaJubilado(this.datosJubilado, this.user.userName)
      .subscribe(
        (respuesta: any) => {
          this.response = respuesta;
          this.visible = false;
          this.mostrarAlerta(new MensajesAlertaForm("ok", this.response.message))
        },
        (error: any) => {
          this.response = error.error;
          this.visible = true;
          this.mostrarAlerta(new MensajesAlertaForm("error", this.response.error))
        }
      );
  }

  agregarComentario() {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .adicionarContacto(this.datosContactoJubilado, this.user.userName)
      .subscribe(
        respuesta => {
         this.mostrarAlertaValidacion(new MensajesAlertaForm("ok", respuesta.message));
         this.consultarContactosJubilado(this.datosContactoJubilado.numeroDocumento);
         this.limpiarFormularioContacto();
        },
        (respuestaError: any) => {
          this.mostrarAlertaValidacion(new MensajesAlertaForm("error", respuestaError.error.error))
        });
  }


  darAlta() {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .darAltaJubilado(this.datosJubilado, this.user.userName)
      .subscribe(
        (respuesta: any) => {
          this.response = respuesta;
          this.visible = false;
          this.mostrarAlerta(new MensajesAlertaForm("ok", this.response.message))
        },
        (error: any) => {
          this.response = error.error;
          this.visible = true;
          this.mostrarAlerta(new MensajesAlertaForm("error", this.response.error))
        }
      );
  }

  actualizar() {
    this.loadingService.mostrarLoading();
    this.jubiladosService
      .actualizarJubilado(this.datosJubilado)
      .subscribe(
        respuesta => {
          this.mostrarAlerta(new MensajesAlertaForm("ok", respuesta.message))
        },
        (respuestaError: any) => {
          this.mostrarAlerta(new MensajesAlertaForm("error", respuestaError.error.error))
        }
      );
  }

  /**Mostrar mensajes en el modal de alertas.
   * exito, invalido(advertencia), error, info.
   * Recibe como parámetro un objeto con el estado, que es cualquiera de los anteriores,
   * y el mensaje a mostrar */
  mostrarAlerta(objValidacion: MensajesAlertaForm) {
    this.setearDetalleAlerta(objValidacion);
    this.loadingService.ocultarLoading();

  }

  /**Mostrar mensajes en el modal de alertas.
   * exito, invalido(advertencia), error, info.
   * Recibe como parámetro un objeto con el estado, que es cualquiera de los anteriores,
   * y el mensaje a mostrar */
  mostrarAlertaValidacion(objValidacion: MensajesAlertaForm) {
    this.setearContactoAlerta(objValidacion);
    this.loadingService.ocultarLoading();
  }

  /**Crear objeto de alerta y pasarlo al servicio de alertas */
  setearDetalleAlerta(objValidacion: MensajesAlertaForm) {
    this.mensajesAlertaService.setearDetalleAlerta(objValidacion);

    abrirModal("#view-alerts");
    //Abrir modal alerta
    setTimeout(() => {
      cerrarModal("#view-alerts");
      this.limpiarFormulario();
      this.CerrarFormulario();
      this.consultarJubilados("{}");
    }, 2500);

  }

  /**Crear objeto de alerta y pasarlo al servicio de alertas */
  setearContactoAlerta(objValidacion: MensajesAlertaForm) {
    this.mensajesAlertaService.setearDetalleAlerta(objValidacion);

    abrirModal("#view-alerts");
    //Abrir modal alerta
    setTimeout(() => {
      cerrarModal("#view-alerts");
    }, 1500);

  }

  CerrarFormulario() {
    accionCerrarFormulario();
  }

  removerHora(datElement: any) {
    return this.datePipe.transform(datElement, 'yyyy-MM-dd');
  }

  seleccionarTipoDoc(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.tipoArchivo = target.value;
    this.limpiarMensajes();
  }

  seleccionarArchivo(event: Event) {
    const target = event.target as HTMLInputElement;
    this.archivoSeleccionado = target.files!.item(0);
    this.limpiarMensajes();
  }

  subirArchivo(): void {
    if (this.archivoSeleccionado) {
      this.limpiarMensajes();
      this.subirArchivoServicio(this.archivoSeleccionado);
    }
    else {
      this.mensaje_error = "Adjunte el archivo para continuar";
      this.mensaje_ok = "";
    }
  }

  limpiarMensajes(): void {
    this.mensaje_error = "";
    this.mensaje_ok = "";
  }

  limpiarCargaArchivos(): void {
    this.tipoArchivo = "";
    this.archivoSeleccionado = null;
    (<HTMLInputElement>document.getElementById('file')).value = "";
  }

  subirArchivoServicio(file: File): void {
    if (this.tipoArchivo) {
      this.textoBoton = "Cargando";
      this.isDisabled = true;
      this.isLoading = true;
      this.jubiladosService.upload(file, this.datosJubilado.numeroDocumento, this.tipoArchivo, this.user.userName).subscribe(
        respuesta => {
          this.textoBoton = "Cargar archivo";
          this.isDisabled = false;
          this.isLoading = false;
          this.mensaje_ok = respuesta.message;
          this.mensaje_error = "";
          this.limpiarCargaArchivos();
        },
        (respuestaError: any) => {
          this.textoBoton = "Cargar archivo";
          this.isDisabled = false;
          this.isLoading = false;
          this.mensaje_error = "Ocurrió un problema en el proceso de carga en P8";
          this.mensaje_ok = "";
        }
      );
    }
    else {
      this.mensaje_error = "Seleccione el tipo de archivo para continuar";
      this.mensaje_ok = "";
    }
  }

  descargarInforme() {
    this.jubiladosService.descargarInforme(this.user.userName).subscribe(
      blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ReporteJubilados.xlsx`;
        a.click();
      }, (_) => {
        this.mostrarAlerta(new MensajesAlertaForm("error", "Se presento un error durante la descarga del archivo"))
      }
    );
  }
}

