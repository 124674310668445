import { Component, OnInit, Input, Output, EventEmitter, Compiler } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultaRecursosService } from 'src/app/core/security/consulta-recursos.service';


declare var close_menu: any;
declare var ejecutarEventosIniciales: any;
declare var jQuery: any;
declare var $: any;

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html'
})
export class MenuComponent implements OnInit {
    @Input() esMaestro: boolean=false;
    @Input() esAutorizadoCrearAsesor: boolean=false;
    @Output() onModuleChange = new EventEmitter<boolean>();
    @Output() onModuleChange_asesores = new EventEmitter<boolean>();
    moduloActual: string;
    lista: Object[] = [];
  // @ts-ignore
    msgErrorFecha: string;

    constructor(public router: Router, public _runtimeCompiler: Compiler, public consultaRecursos: ConsultaRecursosService) {
        this.moduloActual = "GestionMaster";
        this._runtimeCompiler.clearCache();
    }

    ngOnInit() {
        ejecutarEventosIniciales();
      this.consultaRecursos
            .obtenerRecursos()
            .subscribe(
            // @ts-ignore
            respuesta => this.setearRespuesta(<any>respuesta),
            // @ts-ignore
            error => this.gestionarError(<any>error)
            );
    }

    eventoToggleItemsMenu(event: any) {
        $(event.target).toggleClass('closed').siblings('.cd-filter-content').slideToggle(300);
    }

    gestionarError(error: any) {

        let errorMessage = error ? error : "";

        if ((errorMessage) && (errorMessage["modalMensaje"])) {
            if (errorMessage["modalMensaje"] === this.msgErrorFecha) {
                this.lista = [];
            }
        }
        // this.mostrarAlerta(error);
    }



    setearRespuesta(respuesta: Object) {
        // @ts-ignore
      const menu = JSON.parse(respuesta['rs']);
        const items: any[] = menu[0]['menuItems'];
        items.forEach(element => {
          this.lista.push(element.name);
        });
    }

    validarRecurso(recurso: string): boolean {
        for (let i in this.lista) {
            if (this.lista[i] == recurso) {
                return true;
            }
        }
        return false;
    }

    mostrarComponente(componente: string) {
        this.agregarClaseModuloActivo(componente);
        let link = [componente];
        this.router.navigate(link);
         close_menu();
    }



    validarModulo_asesores(esAutorizadoCrearAsesor: boolean) {
        this.onModuleChange_asesores.emit(esAutorizadoCrearAsesor);
        this.esAutorizadoCrearAsesor = esAutorizadoCrearAsesor;
    }

    agregarClaseModuloActivo(modulo: string) {
        this.moduloActual = modulo;

    }

    setAutorizadoCrearAsesor(esAutorizadoCrearAsesor: boolean) {
        this.esAutorizadoCrearAsesor = esAutorizadoCrearAsesor;
        this.validarModulo_asesores(this.esAutorizadoCrearAsesor);
    }


    /** Evento de abrir y contraer los subitems de un item del menú*/

    goHome() {
        window.location.assign(window.location.origin+'/');
    }


}
