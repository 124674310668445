/**
 * Constantes de la aplicación
 */

import { environment } from '../../../environments/environment';

export class Constants {
  /** contexto de la aplicación, debe ser el mismo definido en el web.xml del backend */
  public static get contextoServicios(): string { return `${environment.urlDominioApiSeus}`; }

   /** url para obtener información del usuario */
  public static get securityUserInfo(): string { return 'security/user'; };

  /** url para concer si un usuario tiene permiso hacia un recurso */
  public static get securityUserAccess(): string { return 'security/access'; };

  /** url para obtener comprobar que un usuario tenga activa una sesión y en caso contrario
   *  obtener el html del formulario de login
   */
  public static get securityCheckStatus(): string { return 'security/status'; };

// gen_constants

public static get API_REST_LISTAS(): string
{
  return `${environment.urlApiRest}listas-referencia-rest/rest/listas-referencia/`;
}
public static get API_ENDPOINT(): string { return `${environment.urlDominioApi}`; }


public static get ESTADO_MODAL_ALERTA_SUCCESS(): string { return 'ok'; }
public static get ESTADO_MODAL_ALERTA_INFO(): string { return 'info'; }
public static get ESTADO_MODAL_ALERTA_ERROR(): string { return 'error'; }
public static get ESTADO_MODAL_ALERTA_WARNING(): string { return 'invalido'; }
public static get MSG_ERROR_SESION_EXPIRADA(): string { return 'sesion_expirada'; }
public static get DIRECCION_ORDEN_ASCENDENTE(): string { return 'ASC'; }
public static get DIRECCION_ORDEN_DESCENDENTE(): string { return 'DESC'; }
public static get REGEXP_NUMERO(): RegExp { return /^-?[\d.]+(?:e-?\d+)?$/; }
static URL_LISTA_JUBILADOS: string="listarJubilados";
static URL_LISTA_CONTACTOS_JUBILADO: string="listarContactosJubilado?cdempleado=";
static URL_REGISTRAR_JUBILADO: string="insertarJubilado";
static CIUDADES_ENDPOINT: string="departamentos/CO";
static URL_BAJA_JUBILADOS: string="darBajaJubilado"; 
static URL_ALTA_JUBILADOS: string="darAltaJubilado";
static URL_CARGA_DOCUMENTO_P8: string="cargarDocumento";
static URL_REGISTRAR_CONTACTO_JUBILADO: string="insertarContactoJubilado";
static URL_ACTUALIZAR_JUBILADO: string="actualizarJubilado";
static URL_LISTA_DEPARTAMENTOS: string="maestro/listarDepartamentos";
static URL_DESCARGAR_INFORME: string="exportarJubilados";
static CONSULTA_RECURSOS: any;
static CONSULTA_USUARIO: any;
}
