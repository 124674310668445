import { Component, OnInit, Compiler, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesAlertaForm } from './core/api-services/mensajes-alertas.service';
import {MensajesAlertasService } from './core/api-services/mensajes-alertas.service';
import { LoadingService } from './core/api-services/loading.service';
import { ConsultaRecursosService } from './core/security/consulta-recursos.service';
import {IdentityService, IdentityUser} from "./core/security";
import { Constants } from './core/utils/constants';

declare var ejecutarEventosIniciales: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: ['.hidden-btn-crear {display: none !important} .hidden_loading {display: none !important}'],
  providers: []
})

export class AppComponent implements OnInit, AfterViewInit {
  contexto = Constants.API_ENDPOINT;
  lista: Object[] = [];
  msgErrorFecha: string="";
  msgError: Object="";
  esMaestro = false;
  esAutorizadoCrearAsesor = false;
  // Variables para el modal de alertas
  // @ts-ignore
  objAlerta: MensajesAlertaForm;
  isLoading: boolean=false;
  // @ts-ignore
  user: IdentityUser;
  fecha: string="";
  errorServiceFilter: any;



  constructor(private router: Router, private mensajesAlertaService: MensajesAlertasService,
    private loadingService: LoadingService, protected _runtimeCompiler: Compiler,
    private consultaRecursos: ConsultaRecursosService, private cdr: ChangeDetectorRef,
              private identityService: IdentityService) {

    mensajesAlertaService.detalleAlertas$.subscribe(
      // @ts-ignore
      alerta => {
        this.objAlerta = alerta
        console.log(this.objAlerta);
      }
    );
    loadingService.isLoading$.subscribe(
      load => setTimeout(() => this.isLoading = load, 0)
    );
    this._runtimeCompiler.clearCache();
  }

  ngOnInit() {
    console.log('Pipeline runs successful');
    ejecutarEventosIniciales();
    this.consultarUsuario();

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  consultarUsuario() {
    this.identityService
      .getUserInfo()
      .subscribe((userInfo) => (this.user = userInfo));
  }



  navigateLogout(logout: any) {
    location.assign(logout);
  }





  setearRespuesta(respuesta: Object) {
    // @ts-ignore
    const menu = JSON.parse(respuesta['rs']);
        const items: any[] = menu[0]['menuItems'];
        items.forEach(element => {
          this.lista.push(element.name);
        });
  }



  onModuleChange(esMaestro: boolean) {
    this.esMaestro = esMaestro;
  }

  onModuleChange_asesores(esAutorizadoCrearAsesor: boolean) {
    this.esAutorizadoCrearAsesor = esAutorizadoCrearAsesor;
  }


}
