<!-- pre-loader -->
<div id="loader-wrapper" style="display:block" *ngIf="isLoading">
<div id="loader"></div>
</div>
<!-- pre-loader -->
<!-- Navbar -->
<nav class="navbar navbar-blue navbar-static-top">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" style="cursor: pointer">
        <img src="assets/img/logo.png" alt="Sura">
      </a>
      <div class="line"></div>
      <div class="brand">Gestión Jubilados Suramericana</div>
    </div>
  </div>
  <div class="dropdown user-menu">
    <button class="btn btn-user dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
      <app-menu-superior *ngIf="user != null" [usuario]="user.fullName"></app-menu-superior> <img src="assets/img/avatar.png" alt="User">
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu pull-right" aria-labelledby="dropdownMenu1">
      <li *ngIf="user != null" (click)="navigateLogout(user.fullUrlLogout)">
        <a href="{{user.fullUrlLogout}}">Cerrar Sesión</a>
      </li>
    </ul>
  </div>
</nav>
<!-- End Navbar -->
<!-- Content -->
<main class="cd-main-content navbar-fixed-top ">
  <!-- Options Navbar -->
  <div class="cd-tab-filter-wrapper">
    <div class="cd-tab-filter">

    </div>
  </div>
  <!-- End Option Navbar -->

  <router-outlet></router-outlet>

  <!-- Side bar -->
  <div class="cd-filter filter-is-visible">

    <app-menu  *ngIf="user" [esMaestro]="esMaestro" (onModuleChange)="onModuleChange($event)" [esAutorizadoCrearAsesor]="esAutorizadoCrearAsesor"
              (onModuleChange_asesores)="onModuleChange_asesores($event)"></app-menu>

    <a style="cursor: pointer" class="cd-close "><i class="fa fa-times" aria-hidden="true"></i></a>
  </div>
  <!-- End Sidebar -->
  <a style="cursor: pointer" class="cd-filter-trigger filter-is-visible"><i class="fa fa-bars " aria-hidden="true "></i>
    &nbsp;Menú</a>
</main>
<!-- End Content -->

<!-- Modal alerts -->
<div data-backdrop="false"  class="modal fade" id="view-alerts" tabindex="-1" role="dialog" aria-labelledby="view-alerts">
  <div class="modal-dialog states" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Mensaje</h4>
      </div>
      <div class="modal-body">
        <div class="content-code">
          <div *ngIf="objAlerta && objAlerta.modalEstado == 'ok'" class="alert alert-success">
            <i class="fa fa-check" aria-hidden="true"></i>
            <span [innerHtml]="objAlerta.modalMensaje"></span>
          </div>
          <div *ngIf="objAlerta && objAlerta.modalEstado == 'info'" class="alert alert-info">
            <i class="fa fa-info" aria-hidden="true"></i>
            <strong>Información </strong> <span [innerHtml]="objAlerta.modalMensaje"></span>
          </div>
          <div *ngIf="objAlerta && objAlerta.modalEstado == 'invalido'" class="alert alert-warning">
            <i class="fa fa-exclamation" aria-hidden="true"></i>
            <strong>Advertencia </strong> <span [innerHtml]="objAlerta.modalMensaje"></span>
          </div>
          <div *ngIf="objAlerta && objAlerta.modalEstado == 'error'" class="alert alert-danger">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span [innerHtml]="objAlerta.modalMensaje"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
