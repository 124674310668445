import { Routes ,RouterModule } from '@angular/router';
import { MenuComponent } from './views/shared/menu/menu.component';
import { MenuSuperiorComponent } from './views/shared/menu-superior/menu-superior.component';
import { Error403Component } from './views/shared/global-error-components';
import { AuthorizationGuard } from '../core/security';
import {GestionJubiladosComponent} from "./views/gestion-jubilados/gestion-jubilados.component";
import {AppComponent} from "../app.component";
// gen_imports


/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [

  {
    path: '',
    children: [
      {path: 'GestionJubilados',component: GestionJubiladosComponent,canActivate: [AuthorizationGuard]}
    ]
    },

  // Not found
  { path: '**', redirectTo: '' },
 // errors
  { path: 'access-denied', component: Error403Component }

];

export const routing = RouterModule.forRoot(APP_ROUTES, { useHash: true });
