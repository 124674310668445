import {Observable} from "rxjs/Observable";
import {map, publishLast, refCount} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from "@angular/common/http";
import { Constants } from "../utils/constants";
import { DatosJubiladoModel } from "../models/DatosJubiladoModel";
import { ResponseModel } from "../models/ResponseModel";
import { DatosContactoJubiladoModel } from "../models/DatosContactoJubiladoModel";

@Injectable({
  providedIn: 'root'
})
export class ApiService  {


  constructor(protected http: HttpClient) {
  }


  consultarJubilados(params: string): Observable<Object> {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get(Constants.API_ENDPOINT+Constants.URL_LISTA_JUBILADOS,  { headers })
      .pipe(publishLast(), refCount());
  }

  consultarContactosJubilado(params: string): Observable<Object> {
    return this.http.get(Constants.API_ENDPOINT+Constants.URL_LISTA_CONTACTOS_JUBILADO+params)
      .pipe(publishLast(), refCount());
  }

  adicionarSustituto(jubilado: DatosJubiladoModel, userName: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    const params = new HttpParams()
    .set('usuario', userName);

    return this.http.post<ResponseModel>(Constants.API_ENDPOINT+Constants.URL_REGISTRAR_JUBILADO,
      jubilado,
      {  headers, params });
  }

  darBajaJubilado(jubilado: DatosJubiladoModel, userName: string): Observable<Object> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    const params = new HttpParams()
    .set('usuario', userName);

    return this.http.post<ResponseModel>(Constants.API_ENDPOINT+Constants.URL_BAJA_JUBILADOS,
      jubilado,
      {  headers, params })
      .pipe(publishLast(), refCount());
    }

  adicionarContacto(contactoJubilado: DatosContactoJubiladoModel, userName: string) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
    
        const params = new HttpParams()
        .set('usuario', userName);
        
    return this.http.post<ResponseModel>(Constants.API_ENDPOINT+Constants.URL_REGISTRAR_CONTACTO_JUBILADO,
      contactoJubilado,
      {  headers, params });
  }

  darAltaJubilado(jubilado: DatosJubiladoModel, userName: string): Observable<Object> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    const params = new HttpParams()
    .set('usuario', userName);

    return this.http.post<ResponseModel>(Constants.API_ENDPOINT+Constants.URL_ALTA_JUBILADOS,
      jubilado,
      {  headers, params })
      .pipe(publishLast(), refCount());
  }

  actualizarJubilado(jubilado: DatosJubiladoModel) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');

    return this.http.post<ResponseModel>(Constants.API_ENDPOINT+Constants.URL_ACTUALIZAR_JUBILADO,
      jubilado,
      {  headers });
  }


  protected extractData(res: Response) {
    if (res.status < 200 || res.status >= 300) {
      //validar si es error 401 y redirigir a pagina de error 401
      if (res.status == 401) {
        window.location.replace("error-401.html");
        throw new Error(Constants.MSG_ERROR_SESION_EXPIRADA);
      }
      throw new Error('Bad response status: ' + res.status);
    }
    //validar si es la página de error 401 y redirigir
    if (res.url && res.url.endsWith("/error-401.html")) {
      window.location.replace("error-401.html");
      throw new Error(Constants.MSG_ERROR_SESION_EXPIRADA);
    }
    let body = res.json();
    return body || {};
  }

  public upload(fileToUpload: File, numeroDocumento: string, tipo: string, user: string) {

    const params = new HttpParams()
    .set('usuario', user)
    .set('documento', numeroDocumento)
    .set('tipo', tipo);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');

    const formData = new FormData();
    formData.append('archivo', fileToUpload, fileToUpload.name);

    return this.http.post<ResponseModel>(
      `${Constants.API_ENDPOINT}${Constants.URL_CARGA_DOCUMENTO_P8}`,
      formData,
      {  headers, params }
    );

  }

  descargarInforme(user: string){
    const params = new HttpParams()
    .set('usuario', user);

    return this.http.get(`${Constants.API_ENDPOINT}${Constants.URL_DESCARGAR_INFORME}`, { params, responseType: 'blob'});
  }
}
