import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
@Injectable({
  providedIn: 'root'
})
export class MensajesAlertasService {

  constructor() { }

  // Observable string sources
  private detalleAlertas = new Subject<MensajesAlertaForm>();

  // Observable string streams
  detalleAlertas$ = this.detalleAlertas.asObservable();

  // Service message commands
  setearDetalleAlerta(objAlerta: MensajesAlertaForm) {
    this.detalleAlertas.next(objAlerta);
  }
}

export class MensajesAlertaForm {
  modalEstado: string;
  modalMensaje: string;

  constructor(modalEstado: string, modalMensaje: string) {
    this.modalEstado = modalEstado;
    this.modalMensaje = modalMensaje;
  }
}
