import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // @ts-ignore
  photo: string;
  // @ts-ignore
  logo: string;
  constructor() { }

  ngOnInit(): void {
  }
  errorphoto() {
    this.photo = 'assets/images/userPhoto.png';
  }

  errorlogo() {
    this.logo = 'assets/images/logo-sura.png';
  }
}
