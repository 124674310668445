// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Aqui se pueden definir variables que se cargan cuando se ejecuta la aplicacion en local o cuando se compila sin el comando buildpnd
 */
export const environment = {
  /**
   * indica si el ambiente es productivo, cuando es true se habilita la configuracion de angular para aplicaciones en producción
   */
  production: false,
  urlDominioApi: 'http://local.suramericana.com.co:8091/jubilados/api/',
  urlDominioApiSeus: 'http://local.suramericana.com.co:8091/jubilados/',
  urlApiRest: 'https://appslab.suranet.com/',
  urlBase: 'http://localhost.suranet.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
