import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  // Observable string sources
  public isLoading = new Subject<boolean>();

  // Observable string streams
  isLoading$ = this.isLoading.asObservable();

  // Service message commands
  mostrarLoading() {
    this.isLoading.next(true);
  }
  ocultarLoading() {
    this.isLoading.next(false);
  }
}
