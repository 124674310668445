export class DatosJubiladoModel {
    // @ts-ignore
    cdempleado: string = null;
    // @ts-ignore
    nombre: string;
    // @ts-ignore
    primerNombre: string = null;
    // @ts-ignore
    segundoNombre: string = null;
    // @ts-ignore
    primerApellido: string = null;
    // @ts-ignore
    segundoApellido: string = null;
    // @ts-ignore
    tipoDocumento: string = null;
    // @ts-ignore
    numeroDocumento: string = null;
    // @ts-ignore
    fechaNacimiento: string = null;
    // @ts-ignore
    descripcionEstadoCivil: string = null;
    // @ts-ignore
    sexo: string = null;
    // @ts-ignore
    telefonoFijo: string = null;
    // @ts-ignore
    celular: string = null;
    // @ts-ignore
    correoPersonal:string = null;
    // @ts-ignore
    ciudadResidencia: string = null;
    // @ts-ignore
    direccionResidencia: string = null;
    // @ts-ignore
    estadoVinculacion: string = null;
    // @ts-ignore
    cargo: string = null;
    // @ts-ignore
    fechaIngreso: string = null;
    // @ts-ignore
    fechaInicioPago: string = null;
    // @ts-ignore
    salario: string = null;
    // @ts-ignore
    tipoJubilacion: string = null;
    // @ts-ignore
    fechaExpedicionCedula: string = null;
    // @ts-ignore
    cdTipoDocumentoJubiladoPpal: string = null;
    // @ts-ignore
    nmDocumentoJubiladoPpal: string = null;
    // @ts-ignore
    dsNombreJubiladoPpal: string = null;
    // @ts-ignore
    cdEstadoSupervivencia: string = null;
    // @ts-ignore
    fechaRetiro:string = null
    // @ts-ignore
    compania: string;
    // @ts-ignore
    tipoCuenta: string;
    // @ts-ignore
    numeroCuenta: string;
    // @ts-ignore
    entidadBancaria: string;
  }
  