<!-- Management Table -->
<section class="cd-gallery">
  <div class="content-table">
    <div class="header-table">
      <div class="name-table-center">Gestión Jubilados</div>
      <div class="name-table-right">
        <button type="button" class="btn btn-blue m-right" (click)="descargarInforme()" style="float: right;">
          Descargar informe
        </button>
      </div>
    </div>

    <div class="table">
      <p-table [columns]="jubiladosCols" [value]="lista" [scrollable]="true" scrollHeight="330px">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" style="width: auto;">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr class="p-datatable-order ui-thead-claims header text-left">
            <th *ngFor="let col of columns" scope="col" class="col table-lg">
              {{col.header}}
            </th>
          </tr>

        </ng-template>
        <ng-template pTemplate="body" let-dat let-columns="columns">
          <tr class="ui-tbody-claims text-left">
            <td *ngFor="let col of columns">
              <div *ngIf="col.field  == 'actions'">
                  <em class="btn btn-table fa fa-pencil-square-o" tooltip="Editar" tooltipAppendToBody="body" (click)="verDetalle(dat)" ></em>
                  <em class="btn btn-table fa fa-comment" tooltip="Contacto" tooltipAppendToBody="body" (click)="verContacto(dat)" ></em>
              </div>
              <ng-container *ngIf="col.formato == 'fecha'">
                {{removerHora(dat[col.field])}}
              </ng-container>
              <ng-container *ngIf="col.formato == 'texto'">
                {{dat[col.field]}}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</section>
<!-- End Management Table -->

<div role="tabpanel" class="tab-pane">
  <ng-template [ngIf]="this.operacion == 'detalle'">

    <!--Formulario-->
    <div class="cd-panel from-right">
      <header class="cd-panel-header">
        <h1>Información {{datosJubilado.tipoJubilacion}} - {{datosJubilado.primerNombre}}
          {{datosJubilado.primerApellido}}</h1>
        <a (click)="limpiarFormulario();CerrarFormulario()" style="cursor: pointer" class="cd-panel-close">Close</a>
      </header>
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <form enctype="multipart/form-data">
            <div class="row">
              <div class="col-md-12">
                <div class="group">
                  <input [disabled]="true" [(ngModel)]="datosJubilado.cdempleado" [ngModelOptions]="{standalone: true}"
                    id="Código" class="readonly" type="text" required>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>*Código <span data-toggle="tooltip" data-placement="top"
                      data-original-title="Digite el nombre o palabra relacionada"> <i class="fa fa-info-circle"></i>
                    </span></label>
                </div>
                <div class="group">
                  <select [disabled]="tipoValidacionJubilado!=='S' ? true : false"
                    [(ngModel)]="datosJubilado.tipoDocumento" [ngModelOptions]="{standalone: true}" type="text"
                    required>
                    <option value="" selected>Seleccione...</option>
                    <option value="C">Cédula de ciudadanía</option>
                    <option value="TI">Tarjeta de Identidad</option>
                    <option value="A">NIT</option>
                    <option value="F">Identificación fiscal para extranjeros</option>
                    <option value="E">Cédula de extranjería</option>
                  </select>
                  <label>*Tipo Documento</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.numeroDocumento" [ngModelOptions]="{standalone: true}" type="text"
                    [disabled]="tipoValidacionJubilado!=='S' ? true : false" required>
                  <label>*Número documento</label>
                </div>
                <div class="group">
                  <input [ngModel]="datosJubilado.fechaExpedicionCedula | date:'yyyy-MM-dd'"
                    (ngModelChange)="datosJubilado.fechaExpedicionCedula=$event" id="fecha_expedicion" #fecha_expedicion
                    [ngModelOptions]="{standalone: true}" type="date" placeholder="AAAA/MM/DD" required>
                  <label>*Fecha Expedición</label>
                </div>
                <div class="group">
                  <input [ngModel]="datosJubilado.fechaNacimiento | date:'yyyy-MM-dd'"
                    (ngModelChange)="datosJubilado.fechaNacimiento = $event" id="fecha_nacimiento" #fecha_nacimiento
                    [ngModelOptions]="{standalone: true}" type="date" placeholder="AAAA/MM/DD" required>
                  <label>*Fecha Nacimiento</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.primerNombre" [ngModelOptions]="{standalone: true}" type="text"
                    required>
                  <label>*Primer Nombre</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.segundoNombre" [ngModelOptions]="{standalone: true}" type="text">
                  <label>Segundo Nombre</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.primerApellido" [ngModelOptions]="{standalone: true}" type="text"
                    required>
                  <label>*Primer Apellido</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.segundoApellido" [ngModelOptions]="{standalone: true}" type="text">
                  <label>Segundo Apellido</label>
                </div>
                <div class="group">
                  <select [(ngModel)]="datosJubilado.sexo" [ngModelOptions]="{standalone: true}" type="text" required>
                    <option value="" selected>Seleccione...</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </select>
                  <label>*Sexo</label>
                </div>
                <div class="group">
                  <select [(ngModel)]="datosJubilado.descripcionEstadoCivil" [ngModelOptions]="{standalone: true}"
                    type="text" required>
                    <option value="" selected>Seleccione...</option>
                    <option value="C">CASADA</option>
                    <option value="V">VIUDA</option>
                    <option value="U">UNION LIBRE</option>
                    <option value="S">SOLTERA</option>
                  </select>
                  <label>*Estado Civil</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.telefonoFijo" [ngModelOptions]="{standalone: true}" type="text">
                  <label>Telefono Fijo</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.celular" [ngModelOptions]="{standalone: true}" type="text">
                  <label>Telefono Celular</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.correoPersonal" [ngModelOptions]="{standalone: true}" type="text">
                  <label>Correo Personal</label>
                </div>
                <div class="group">
                  <select [(ngModel)]="datosJubilado.ciudadResidencia" [ngModelOptions]="{standalone: true}" type="text"
                    required>
                    <option [value]="ciudad.cod" *ngFor="let ciudad of listaCiudades">{{ciudad.nombre}}</option>
                  </select>
                  <label>*Ciudad de residencia</label>
                </div>
                <div class="group">
                  <input [(ngModel)]="datosJubilado.direccionResidencia" [ngModelOptions]="{standalone: true}"
                    type="text">
                  <label>Dirección</label>
                </div>
                <div class="group">
                  <select [(ngModel)]="datosJubilado.estadoVinculacion" [ngModelOptions]="{standalone: true}"
                    type="text" [disabled]="true" required>
                    <option value="" selected>Seleccione...</option>
                    <option value="A">Activo</option>
                    <option value="R">Retirado</option>
                  </select>
                  <label>*Estado Vinculación</label>
                </div>
                <div class="group">
                  <select [disabled]="true" [(ngModel)]="datosJubilado.cargo"
                    [ngModelOptions]="{standalone: true}" type="text" required>
                    <option value="" selected>Seleccione...</option>
                    <option value="JUBILADO">JUBILADO</option>
                    <option value="ASESOR DE SEGUROS">ASESOR DE SEGUROS</option>
                  </select>
                  <label>*Cargo</label>
                </div>
                <div class="group">
                  <input [disabled]="true" [ngModel]="datosJubilado.fechaIngreso | date:'yyyy-MM-dd'"
                    (ngModelChange)="datosJubilado.fechaIngreso=$event" id="fecha_fechaIngreso" #fecha_fechaIngreso
                    [ngModelOptions]="{standalone: true}" type="date" placeholder="AAAA/MM/DD" required>
                  <label>*Fecha Ingreso</label>
                </div>
                <div class="group">
                  <input  [ngModel]="datosJubilado.fechaInicioPago | date:'yyyy-MM-dd'"
                    (ngModelChange)="datosJubilado.fechaInicioPago=$event" id="fecha_fechaInicioPago"
                    #fecha_fechaInicioPago [ngModelOptions]="{standalone: true}" type="date" placeholder="AAAA/MM/DD"
                    [disabled]="tipoValidacionJubilado==='S' ? true : false"
                    required>
                  <label>*Fecha Inicio Pago</label>
                </div>
                <div class="group">
                  <input [disabled]="tipoValidacionJubilado==='S' ? true : false" [(ngModel)]="datosJubilado.salario"
                    [ngModelOptions]="{standalone: true}" type="text" required>
                  <label>*Salario</label>
                </div>
                <div class="group">
                  <select [disabled]="true" [(ngModel)]="datosJubilado.tipoJubilacion"
                    [ngModelOptions]="{standalone: true}" type="text" required>
                    <option value="" selected>Seleccione...</option>
                    <option value="Jubilado">PRINCIPAL</option>
                    <option value="Sustituto">SUSTITUTO</option>
                  </select>
                  <label>*Tipo Jubilación</label>
                </div>
                <div class="group">
                  <select [disabled]="true" [(ngModel)]="datosJubilado.cdEstadoSupervivencia"
                    [ngModelOptions]="{standalone: true}" type="text" required>
                    <option value="" selected>Seleccione...</option>
                    <option value="V">VIGENTE</option>
                    <option value="F">CANCELADA POR MUERTE O FALLECIDO</option>
                    <option value="C">CANCELADA</option>
                    <option value="E">NO EXPEDIDA</option>
                    <option value="T">EN TRAMITE</option>
                    <option value="R">EN REVISIÓN</option>
                  </select>
                  <label>Estado de supervivencia</label>
                </div>
                <div class="group">
                  <input [disabled]="tipoValidacionJubilado==='S' ? true : false"
                    [(ngModel)]="datosJubilado.cdTipoDocumentoJubiladoPpal" [ngModelOptions]="{standalone: true}"
                    type="text" required>
                  <label>Tipo Identificación Principal</label>
                </div>
                <div class="group">
                  <input [disabled]="tipoValidacionJubilado==='S' ? true : false"
                    [(ngModel)]="datosJubilado.nmDocumentoJubiladoPpal" [ngModelOptions]="{standalone: true}"
                    type="text" required>
                  <label>Número Identificación Principal</label>
                </div>
                <div class="group">
                  <input [disabled]="tipoValidacionJubilado==='S' ? true : false"
                    [(ngModel)]="datosJubilado.dsNombreJubiladoPpal" [ngModelOptions]="{standalone: true}" type="text"
                    required>
                  <label>Nombre Principal</label>
                </div>
                <div class="group">
                  <input [ngModel]="datosJubilado.fechaRetiro | date:'yyyy-MM-dd'"
                    (ngModelChange)="datosJubilado.fechaRetiro=$event" id="fecha_retiro" #fecha_retiro
                    [ngModelOptions]="{standalone: true}" type="date" placeholder="AAAA/MM/DD"
                    [disabled]="datosJubilado.cdEstadoSupervivencia !== 'R' ? true : false" required>
                  <label>Fecha retiro</label>
                </div>
              </div>
            </div>
            <div class="group">
              <select
                [(ngModel)]="datosJubilado.tipoCuenta" [ngModelOptions]="{standalone: true}" type="text"
                required>
                <option value="" selected>Seleccione...</option>
                <option value="A">Ahorros</option>
                <option value="C">Corriente</option>
              </select>
              <label>*Tipo de Cuenta</label>
            </div>
            <div class="group">
              <select
                [(ngModel)]="datosJubilado.entidadBancaria" [ngModelOptions]="{standalone: true}" type="text"
                required>
                <option [value]="banco.cod" *ngFor="let banco of listaBancos">{{banco.nombre}}</option>
              </select>
              <label>Entidad Bancaria</label>
            </div>
            <div class="group">
              <input
                [(ngModel)]="datosJubilado.numeroCuenta" [ngModelOptions]="{standalone: true}"
                type="text" required>
              <label>Número de Cuenta</label>
            </div>
            <div class="panel panel-default">
              <div class="container-fluid">
                <br>
                <div class="group">
                  <select [disabled]="isDisabled" [ngModel]="tipoArchivo" [ngModelOptions]="{standalone: true}" type="text" (change)="seleccionarTipoDoc($event)">
                    <option value="" selected>Seleccione...</option>
                    <option value="Documentos de retiro">Documento de retiro</option>
                    <option value="Soportes">Soporte</option>
                  </select>
                  <label>Tipo de archivo</label>
                </div>
                <div class="group custom-file">
                  <input [disabled]="isDisabled" class="file-upload" type="file" name="file" id="file" (change)="seleccionarArchivo($event)">
                </div>
                <div class="group">
                  <button [disabled]="isDisabled" type="button" class="btn btn-blue m-right" (click)="subirArchivo()">
                    {{textoBoton}}
                    <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
                  </button>
                  <small *ngIf="mensaje_error" class="text-danger">{{mensaje_error}}</small>
                  <small *ngIf="mensaje_ok" class="text-success">{{mensaje_ok}}</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="buttons">
                  <button *ngIf="datosJubilado.estadoVinculacion !=='R' && datosJubilado.estadoVinculacion !=='A' && tipoValidacionJubilado!=='S'"  type="button"
                    class="btn btn-blue m-right" (click)="darAlta()">
                    Alta en Nómina
                  </button>
                  <button *ngIf="datosJubilado.cdEstadoSupervivencia ==='R' && visible == true && datosJubilado.estadoVinculacion ==='A'" type="button"
                    class="btn btn-blue m-right" (click)="darBaja()">
                    Baja en Nómina
                  </button>
                  <button type="button" class="btn btn-blue m-right"
                    [disabled]="tipoValidacionJubilado==='S' || datosJubilado.estadoVinculacion !=='R' || datosJubilado.tipoJubilacion ==='Sustituto'? true : false"
                    (click)="adicionarSustituto()">
                    Adicionar Sustituto
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right buttons">
                  <button type="button" class="btn btn-blue m-right" (click)="guardar()"
                    *ngIf="tipoValidacionJubilado==='S'; else showUpdateButton">
                    Guardar
                  </button>
                  <ng-template #showUpdateButton>
                    <button [disabled]="true" type="button" class="btn btn-gray m-right" (click)="actualizar()">Actualizar</button>
                  </ng-template>
                  <button type="button" class="btn btn-gray" (click)="limpiarFormulario()">Limpiar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--Formulario-->
  </ng-template>
  <ng-template [ngIf]="this.operacion == 'contacto'">

    <!--Formulario-->
    <div class="cd-panel from-right">
      <header class="cd-panel-header">
        <h1>Información {{datosJubilado.tipoJubilacion}} - {{datosJubilado.primerNombre}} {{datosJubilado.primerApellido}}</h1>
        <a (click)="limpiarFormulario();CerrarFormulario()" style="cursor: pointer" class="cd-panel-close">Close</a>
      </header>
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <div class="tittleForm">Registro de Contactos</div>
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="group">
                  <textarea  [(ngModel)]="datosContactoJubilado.comentario" [ngModelOptions]="{standalone: true}" type="text"
                         required></textarea>
                  <label>*Comentario</label>
                </div>
            </div>
            </div>
            <div class="pull-right buttons">
              <button 
                type="button" 
                class="btn btn-blue m-right" 
                (click)="agregarComentario()"
              >
                Guardar Comentario
              </button>
            </div>
          </form>
          <section>
            <div class="content-table">
              <div class="tittleForm">Historial de Contactos</div>
              <div class="table">
                <p-table [columns]="contactosJubiladoCols" [value]="listaContactoJubilado" [scrollable]="true" scrollHeight="300px">
                  <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                      <col *ngFor="let col of columns" style="width: auto;">
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr class="p-datatable-order ui-thead-claims header text-left">
                      <th *ngFor="let col of columns" scope="col" class="col table-lg">
                        {{col.header}}
                      </th>
                    </tr>
          
                  </ng-template>
                  <ng-template pTemplate="body" let-dat let-columns="columns">
                    <tr class="ui-tbody-claims text-left">
                      <td *ngFor="let col of columns">
                        <ng-container *ngIf="col.formato == 'fecha'">
                          {{removerHora(dat[col.field])}}
                        </ng-container>
                        <ng-container *ngIf="col.formato == 'texto'">
                          {{dat[col.field]}}
                        </ng-container>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
            </div>
            </div>
          </section>
          
        </div>
      </div>
    </div>
    <!--Formulario-->
  </ng-template>
</div>



