import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { map} from 'rxjs/operators';
// @ts-ignore
import { Observable } from 'rxjs';
import { MensajesAlertaForm } from '../api-services/mensajes-alertas.service';
import { Constants } from '../utils/constants';
@Injectable({
  providedIn: 'root'
})
export class ConsultaRecursosService {
  constructor(private http: Http) { }
  private consulta_recursos = Constants.API_ENDPOINT + Constants.CONSULTA_RECURSOS;
  private consulta_usuario = Constants.API_ENDPOINT + Constants.CONSULTA_USUARIO;

  obtenerRecursos(): Observable<Response> {
    return this.http.post(this.consulta_recursos, null)
      .pipe(map(this.extractData))
  }

  consultarUsuario(): Observable<Object> {

    return this.http.post(this.consulta_usuario, null)
      .pipe(map(this.extractData));
  }


  protected extractData(res: Response) {
    if (res.status < 200 || res.status >= 300) {
      //validar si es error 401 y redirigir a pagina de error 401
      if (res.status == 401) {
        window.location.replace("error-401.html");
        throw new Error(Constants.MSG_ERROR_SESION_EXPIRADA);
      }
      throw new Error('Bad response status: ' + res.status);
    }
    //validar si es la página de error 401 y redirigir
    if (res.url && res.url.endsWith("/error-401.html")) {
      window.location.replace("error-401.html");
      throw new Error(Constants.MSG_ERROR_SESION_EXPIRADA);
    }
    let body = res.json();
    return body || {};
  }

  // @ts-ignore
  protected handleError(error: any) {
    //validar si es error 401 y redirigir a pagina 401
    if (error.status == 401) {
      window.location.replace("error-401.html");
      throw new Error(Constants.MSG_ERROR_SESION_EXPIRADA);
    } else if (error.message == Constants.MSG_ERROR_SESION_EXPIRADA) {
      window.location.replace("error-401.html");
    } else {
      // In a real world app, we might send the error to remote logging infrastructure
      let body = error.json();
      let errEstado = body.estado || "info";
      let errMsg = body.validacion || body.error || 'Server error';
      let objAlerta: MensajesAlertaForm = new MensajesAlertaForm(errEstado, errMsg);
      console.error(objAlerta); // log to console instead
      return Observable.throw(objAlerta);
    }
  }
}
